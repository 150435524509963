<script setup lang="ts">
const { t: $t } = useI18n()

import type { CustomFolderTreeFolderOption } from '~/types'

const props = defineProps<{ folder: CustomFolderTreeFolderOption['folder'], entity: FileFolderEntity }>()
const emit = defineEmits<{ (e: 'close'): void }>()
const formData = ref({
  files: props.folder.childrenFiles,
})

/** Mutations */
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const updateFiles = useMutation({
  mutationFn: $trpc.fileFolder.updateFilesInFolder.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('fileFolder.updateFilesInFolder.error') }),
  onSuccess: async () => {
    await Promise.all(getFileFolderQueryInvalidations(props.entity, queryClient))
    notification.success({ title: $t('fileFolder.updateFilesInFolder.success'), duration: 4500 })
    emit('close')
  },
})
</script>

<template>
  <ThePopup
    :title="$t('fileFolder.modal.updateFiles.title', { folderName: folder.name })"
    width="450px"
    show
    @close="emit('close')"
  >
    <FormKit
      v-model="formData"
      type="form"
      @submit="updateFiles.mutate({ folderId: folder.id, files: formData.files })"
    >
      <FormKitFileDropzone
        name="files"
        :label="$t('fileFolder.input.files.label')"
      />
    </FormKit>
  </ThePopup>
</template>
